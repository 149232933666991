
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import { IPushCampaign } from '@/utils/types'
import { ACTION_PUSH } from '@/store/actions'
import { constant } from '@/utils/constants'

@Options({
  components: {},
  directives: { maska },
})
export default class PushCampaignMixin extends Vue {
  get selectedAppId() {
    return this.$route.params.app_id
  }

  get selectedCampaignId() {
    return this.$route.params.push_id || ''
  }

  get actionType() {
    return this.$route.params.action_type || ''
  }

  get selectedScenarioTemplateId() {
    return this.$route.params.scenario_template_id || ''
  }

  async onDelete(push: IPushCampaign) {
    this.$q
      .dialog({
        title: this.$t('confirm'),
        message: this.$t('are_you_sure_you_want_to_delete'),
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        const success = await this.$store.dispatch(ACTION_PUSH.DELETE, push._id)
        if (success) {
          this.$q.notify({
            message: this.$t('messages.deleted'),
            color: 'positive',
          })
        }
      })
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  detailPath(name: string, params = {}) {
    return {
      name: name,
      params: params,
    }
  }

  onCopy(data: IPushCampaign) {
    this.goto('push_setting', {
      app_id: this.selectedAppId,
      push_id: data._id,
      action_type: constant.ACTION_TYPE.COPY,
    })
  }
}
