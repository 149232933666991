
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import { IDateRangePicker, IPushCampaign, IScenarioTemplate, ITargetSetting } from '@/utils/types'
import { Watch } from 'vue-property-decorator'
import { ACTION_PUSH, ACTION_SCENARIO_TEMPLATE, ACTION_TARGET_SETTING } from '@/store/actions'
import cloneDeep from 'lodash/cloneDeep'
import { constant } from '@/utils/constants'
import { Formater } from '@/utils/formater'
import PushCampaignMixin from './mixins/PushCampaignMixin.vue'
import DeliveredLogModal from './DeliveredLogModal.vue'
import CLoading from '@/components/common/ui/CLoading.vue'
import DateRangeSelectorV2 from '@/components/common/ui/DateRangeSelectorV2.vue'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'
import { DatetimeUtils } from '@/utils/datetime'

@Options({
  components: {
    DeliveredLogModal,
    CLoading,
    DateRangeSelectorV2,
  },
  directives: { maska },
  emits: [],
})
export default class PushCampaignList extends mixins(PushCampaignMixin, BaseFormMixin) {
  loading = true
  pushs: IPushCampaign[] = []
  scenario_templates: IScenarioTemplate[] = []
  modalVisible = false
  pushId = ''

  dateRange: IDateRangePicker = {
    startDate: '',
    endDate: '',
  }

  selectedTargetIds = ['all']

  get targetSettings() {
    let result: ITargetSetting[] = []
    const all: ITargetSetting = {
      _id: 'all',
      app_id: this.selectedAppId,
      target_name: this.$t('label.all'),
    }

    result.push(all)
    result = result.concat(this.$store.getters.targetSettings)
    return result
  }

  @Watch('$store.getters.pushs')
  pushChanged() {
    const getpushs = cloneDeep(this.$store.getters.pushs)
    if (getpushs) {
      return (this.pushs = getpushs)
    }
  }

  @Watch('$store.getters.scenarioTemplates')
  scenarioTemplateChanged() {
    const getScenarios = cloneDeep(this.$store.getters.scenarioTemplates)
    if (getScenarios) {
      return (this.scenario_templates = getScenarios)
    }
  }

  @Watch('selectedAppId', { immediate: true })
  async appIdChanged() {
    if (!this.selectedAppId) {
      return
    }
    this.dateRange = DatetimeUtils.getDateRange('last30Days')

    await this.$store.dispatch(ACTION_TARGET_SETTING.LOAD_ITEMS_WITHOUT_DETAIL, {
      app_id: this.selectedAppId,
    })

    await this.loadIntials()
  }

  @Watch('selectedTargetIds')
  handleChangeTargetSetting() {
    if (this.selectedTargetIds === null || this.selectedTargetIds.length === 0) {
      this.selectedTargetIds = ['all']
    }
    this.loadIntials()
  }

  async loadIntials() {
    this.loading = true

    await this.$store.dispatch(ACTION_PUSH.LOAD_ITEMS_SUMMARY, {
      app_id: this.selectedAppId,
      date_from: DatetimeUtils.formatDate(this.dateRange.startDate),
      date_to: DatetimeUtils.formatDate(this.dateRange.endDate),
      target_setting_ids: this.selectedTargetIds,
    })

    await this.$store.dispatch(ACTION_SCENARIO_TEMPLATE.LOAD_ITEMS_WITHOUT_DETAIL, {
      app_id: this.selectedAppId,
    })

    this.loading = false
  }

  async handleToggleActive(record: IPushCampaign) {
    if (!this.validate(record, false)) {
      record.is_active = false
      return
    }

    if (!record.is_active) {
      this.updateIsActive(record)
    } else {
      this.$q
        .dialog({
          title: this.$t('messages.confirm'),
          message: this.$t('messages.are_you_sure_to_turn_on_active_for_this_item'),
          cancel: {
            flat: true,
            label: this.$t('messages.no'),
          },
          ok: {
            flat: true,
            label: this.$t('messages.yes'),
          },
          persistent: true,
        })
        .onOk(async () => {
          this.updateIsActive(record)
        })
        .onCancel(async () => {
          record.is_active = false
        })
    }
  }

  async updateIsActive(record: IPushCampaign) {
    const isSuccess = await this.$store.dispatch(ACTION_PUSH.IS_ACTIVE, record)
    if (!isSuccess) {
      record.is_active = false
    } else {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    return isSuccess
  }

  async handleToggleTest(record: IPushCampaign) {
    if (!this.validate(record, true)) {
      record.is_test_mode = false
      return
    }
    const isSuccess = await this.$store.dispatch(ACTION_PUSH.TEST_MODE, record)
    if (!isSuccess) {
      record.is_test_mode = false
    } else {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }
  }

  onDeliveredLog(pushId: string) {
    this.modalVisible = true
    this.pushId = pushId
  }

  onCloseModal() {
    this.modalVisible = false
  }

  onAdd(scenario_template_id) {
    if (scenario_template_id) {
      this.goto('push_setting', {
        app_id: this.selectedAppId,
        action_type: constant.ACTION_TYPE.ADD,
        scenario_template_id: scenario_template_id,
      })
    } else {
      this.goto('push_setting', { app_id: this.selectedAppId, action_type: constant.ACTION_TYPE.ADD })
    }
  }

  onEdit(data: IPushCampaign) {
    return this.detailPath('push_setting', {
      app_id: this.selectedAppId,
      push_id: data._id,
      action_type: constant.ACTION_TYPE.EDIT,
    })
  }

  onDetails(event, data: IPushCampaign) {
    // Check if Ctrl (Windows) or Meta (Mac) is pressed
    if (event.ctrlKey || event.metaKey) {
      // Let the browser handle opening in a new tab
      return
    }
    // Otherwise, navigate programmatically
    event.preventDefault()

    this.goto('push_details', {
      app_id: this.selectedAppId,
      campaign_type: 'push',
      campaign_id: data._id,
      from_date: DatetimeUtils.formatDate(this.dateRange.startDate),
      to_date: DatetimeUtils.formatDate(this.dateRange.endDate),
    })
  }

  getDetailsUrl(data) {
    // Construct the URL as a string for direct navigation
    return this.$router.resolve({
      name: 'push_details',
      params: {
        app_id: this.selectedAppId,
        campaign_type: 'push',
        story_id: data._id,
        from_date: DatetimeUtils.formatDate(this.dateRange.startDate),
        to_date: DatetimeUtils.formatDate(this.dateRange.endDate),
      },
    }).href
  }

  numberWithCommas(num) {
    return Formater.numberFormat(num)
  }

  roundNumber(num) {
    return Formater.round(num)
  }

  changeDateRange(value) {
    this.dateRange = value

    this.loadIntials()
  }

  validate(record: IPushCampaign, isTestMode: boolean) {
    let detail = record.prod_detail
    if (isTestMode) {
      detail = record.test_detail
    }
    if (detail?.audience?.audience_or?.length === 0) {
      this.$q.dialog({
        title: this.$t('error'),
        message: this.$t('validate.select_audience_or_and_not'),
        persistent: true,
      })
      return false
    }

    if (detail?.scenarios?.length === 0) {
      this.$q.dialog({
        title: this.$t('error'),
        message: this.$t('validate.please_design_scenario'),
        persistent: true,
      })
      return false
    }

    return true
  }
}
