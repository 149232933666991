<template>
  <CLoading :loading="loading" />
  <q-table
    flat
    dense
    title="Push Campaigns"
    v-if="pushs"
    :rows="pushs"
    row-key="title"
    bordered
    ref="welcomeList"
    :rows-per-page-options="[10]"
    separator="cell"
    :no-data-label="$t('messages.no_data_available')"
    class="text-grey-8"
  >
    <template v-slot:header="props">
      <q-tr>
        <q-th key="action" rowspan="2" class="text-center" style="width: 50px"
          ><span class="text-bold">{{ $t('label.prod') }}</span></q-th
        >
        <q-th key="action" rowspan="2" class="text-center" style="width: 50px"
          ><span class="text-bold">{{ $t('label.test') }}</span></q-th
        >
        <q-th key="index" rowspan="2" class="text-left" style="width: 100px"></q-th>
        <q-th :props="props" key="title" rowspan="2"
          ><span class="text-bold">{{ $t('label.push.campaign_name') }}</span></q-th
        >
        <q-th :props="props" key="title" rowspan="2"
          ><span class="text-bold">{{ $t('label.push.next_delivery') }}</span></q-th
        >
        <q-th :props="props" key="audience_size" rowspan="2"
          ><span class="text-bold">{{ $t('label.push.audience_size') }}</span></q-th
        >
        <q-th :props="props" key="delivered" rowspan="2"
          ><span class="text-bold">{{ $t('label.push.delivered') }} </span></q-th
        >

        <q-th :props="props" key="total_user_of_goal" rowspan="2">
          <span class="text-bold">{{ $t('label.total_user_of_goal') }}</span>
        </q-th>
        <q-th :props="props" key="gtr" rowspan="2">
          <span class="text-bold">GTR</span>
        </q-th>
        <q-th :props="props" key="total_click" rowspan="2">
          <span class="text-bold">{{ $t('label.total_click') }}</span>
        </q-th>
        <q-th :props="props" key="success_rate" rowspan="2">
          <span class="text-bold">CVR</span>
        </q-th>
        <q-th :props="props" key="success_count" rowspan="2">
          <span class="text-bold">CV</span>
        </q-th>
        <q-th key="index" rowspan="2"> </q-th>
      </q-tr>
    </template>
    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td>
          <q-toggle
            round
            v-model="props.row.is_active"
            val="props.row.is_active"
            @click="handleToggleActive(props.row)"
          />
        </q-td>
        <q-td>
          <q-toggle
            round
            v-model="props.row.is_test_mode"
            val="props.row.is_test_mode"
            @click="handleToggleTest(props.row)"
          />
        </q-td>
        <q-td key="action" :props="props.index">
          <!-- <q-btn size="sm" flat @click="onRunNow(props.row)" class="q-mr-sm" :disable="!props.row.is_test_mode">
            {{ $t('label.push.send_now') }}
            <q-tooltip> {{ $t('label.push.tooltip_run_right_now') }} </q-tooltip>
          </q-btn> -->

          <q-btn size="sm" flat round icon="edit" :to="onEdit(props.row)" class="q-mr-sm" />
          <q-btn size="sm" flat round icon="content_copy" @click="onCopy(props.row)" class="q-mr-sm" />
          <q-btn size="sm" flat round color="red" icon="delete" @click="onDelete(props.row)" class="q-mr-sm" />
        </q-td>
        <q-td key="title" :props="props">
          {{ props.row.title }}<br />
          <a
            :href="getDetailsUrl(props.row)"
            @click="onDetails(event, props.row)"
            class="cursor-pointer hyper-link-chart"
          >
            {{ $t('label.data_transition') }}
          </a>
          <span>/{{ props.row._id.length > 4 ? props.row._id.slice(0, 4) + '***' : props.row._id }}</span>
        </q-td>
        <q-td key="title" :props="props">
          <template v-if="props.row.is_active && props.row.next_time != ''">
            {{ props.row.next_time == 'delay_time' ? $t('label.delay_time') : props.row.next_time }}<br />
            <span v-if="props.row.next_time !== 'delay_time'">{{
              props.row.number_of_delivery ? props.row.number_of_delivery : 0
            }}</span>
          </template>
          <template v-else>-</template>
        </q-td>
        <q-td key="audience_size" :props="props">
          <q-chip color="primary" text-color="white">
            {{ numberWithCommas(props.row.audience_size) }}
          </q-chip>
        </q-td>
        <q-td key="delivered" :props="props">
          <q-chip color="primary" text-color="white">
            {{ numberWithCommas(props.row.delivered) }}
          </q-chip>
        </q-td>
        <q-td key="total_user_of_goal" :props="props">
          <q-chip color="primary" text-color="white">
            {{ numberWithCommas(props.row.total_user_of_goal) }}
          </q-chip>
        </q-td>
        <q-td key="gtr" :props="props">
          <q-chip color="primary" text-color="white"> {{ roundNumber(props.row.gtr) }}% </q-chip>
        </q-td>
        <q-td key="total_click" :props="props">
          <q-chip color="primary" text-color="white">
            {{ numberWithCommas(props.row.total_click) }}
          </q-chip>
        </q-td>
        <q-td key="success_rate" :props="props">
          <q-chip color="primary" text-color="white"> {{ roundNumber(props.row.success_rate) }}% </q-chip>
        </q-td>
        <q-td key="success_count" :props="props">
          <q-chip color="primary" text-color="white">
            {{ numberWithCommas(props.row.success_count) }}
          </q-chip>
        </q-td>
        <q-td auto-width key="index" class="text-center" :props="props.index">
          <q-btn
            no-caps
            outline
            color="primary"
            :label="$t('label.push.delivered_log')"
            @click="onDeliveredLog(props.row._id)"
          />
        </q-td>
      </q-tr>
    </template>

    <template v-slot:top>
      <h2 class="text-h6 q-mr-md text-black">{{ $t('label.push.push_campaign_list') }}</h2>
      <DateRangeSelectorV2
        v-model="dateRange"
        :disable="false"
        @update:onChangeDateRange="changeDateRange"
      ></DateRangeSelectorV2>
      <q-space />
      <q-select
        outlined
        dense
        multiple
        clearable
        option-value="_id"
        option-label="target_name"
        v-model="selectedTargetIds"
        :options="targetSettings"
        emit-value
        map-options
        class="q-ma-md"
        style="width: 240px"
        :label="$t('label.target_setting.target_setting')"
      />
      <q-btn v-if="scenario_templates.length > 0" no-caps class="btn-save" :label="$t('add_new')">
        <q-menu>
          <q-list dense style="min-width: 200px">
            <q-item clickable v-close-popup @click="onAdd(0)">
              <q-item-section>{{ $t('create_new') }}</q-item-section>
            </q-item>
            <q-item clickable>
              <q-item-section>{{ $t('from_template') }}</q-item-section>
              <q-menu anchor="top end" self="top start">
                <q-list style="min-width: 200px">
                  <q-item
                    v-for="(template, index) in scenario_templates"
                    :key="index"
                    dense
                    clickable
                    @click="onAdd(template._id)"
                  >
                    <q-item-section>{{ template.title }}</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
      <q-btn v-else no-caps class="btn-save" @click="onAdd(0)" :label="$t('add_new')" />
    </template>
  </q-table>

  <DeliveredLogModal
    v-if="modalVisible"
    :modalVisible="modalVisible"
    :pushId="pushId"
    @update:closeModal="onCloseModal"
  />
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import { IDateRangePicker, IPushCampaign, IScenarioTemplate, ITargetSetting } from '@/utils/types'
import { Watch } from 'vue-property-decorator'
import { ACTION_PUSH, ACTION_SCENARIO_TEMPLATE, ACTION_TARGET_SETTING } from '@/store/actions'
import cloneDeep from 'lodash/cloneDeep'
import { constant } from '@/utils/constants'
import { Formater } from '@/utils/formater'
import PushCampaignMixin from './mixins/PushCampaignMixin.vue'
import DeliveredLogModal from './DeliveredLogModal.vue'
import CLoading from '@/components/common/ui/CLoading.vue'
import DateRangeSelectorV2 from '@/components/common/ui/DateRangeSelectorV2.vue'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'
import { DatetimeUtils } from '@/utils/datetime'

@Options({
  components: {
    DeliveredLogModal,
    CLoading,
    DateRangeSelectorV2,
  },
  directives: { maska },
  emits: [],
})
export default class PushCampaignList extends mixins(PushCampaignMixin, BaseFormMixin) {
  loading = true
  pushs: IPushCampaign[] = []
  scenario_templates: IScenarioTemplate[] = []
  modalVisible = false
  pushId = ''

  dateRange: IDateRangePicker = {
    startDate: '',
    endDate: '',
  }

  selectedTargetIds = ['all']

  get targetSettings() {
    let result: ITargetSetting[] = []
    const all: ITargetSetting = {
      _id: 'all',
      app_id: this.selectedAppId,
      target_name: this.$t('label.all'),
    }

    result.push(all)
    result = result.concat(this.$store.getters.targetSettings)
    return result
  }

  @Watch('$store.getters.pushs')
  pushChanged() {
    const getpushs = cloneDeep(this.$store.getters.pushs)
    if (getpushs) {
      return (this.pushs = getpushs)
    }
  }

  @Watch('$store.getters.scenarioTemplates')
  scenarioTemplateChanged() {
    const getScenarios = cloneDeep(this.$store.getters.scenarioTemplates)
    if (getScenarios) {
      return (this.scenario_templates = getScenarios)
    }
  }

  @Watch('selectedAppId', { immediate: true })
  async appIdChanged() {
    if (!this.selectedAppId) {
      return
    }
    this.dateRange = DatetimeUtils.getDateRange('last30Days')

    await this.$store.dispatch(ACTION_TARGET_SETTING.LOAD_ITEMS_WITHOUT_DETAIL, {
      app_id: this.selectedAppId,
    })

    await this.loadIntials()
  }

  @Watch('selectedTargetIds')
  handleChangeTargetSetting() {
    if (this.selectedTargetIds === null || this.selectedTargetIds.length === 0) {
      this.selectedTargetIds = ['all']
    }
    this.loadIntials()
  }

  async loadIntials() {
    this.loading = true

    await this.$store.dispatch(ACTION_PUSH.LOAD_ITEMS_SUMMARY, {
      app_id: this.selectedAppId,
      date_from: DatetimeUtils.formatDate(this.dateRange.startDate),
      date_to: DatetimeUtils.formatDate(this.dateRange.endDate),
      target_setting_ids: this.selectedTargetIds,
    })

    await this.$store.dispatch(ACTION_SCENARIO_TEMPLATE.LOAD_ITEMS_WITHOUT_DETAIL, {
      app_id: this.selectedAppId,
    })

    this.loading = false
  }

  async handleToggleActive(record: IPushCampaign) {
    if (!this.validate(record, false)) {
      record.is_active = false
      return
    }

    if (!record.is_active) {
      this.updateIsActive(record)
    } else {
      this.$q
        .dialog({
          title: this.$t('messages.confirm'),
          message: this.$t('messages.are_you_sure_to_turn_on_active_for_this_item'),
          cancel: {
            flat: true,
            label: this.$t('messages.no'),
          },
          ok: {
            flat: true,
            label: this.$t('messages.yes'),
          },
          persistent: true,
        })
        .onOk(async () => {
          this.updateIsActive(record)
        })
        .onCancel(async () => {
          record.is_active = false
        })
    }
  }

  async updateIsActive(record: IPushCampaign) {
    const isSuccess = await this.$store.dispatch(ACTION_PUSH.IS_ACTIVE, record)
    if (!isSuccess) {
      record.is_active = false
    } else {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    return isSuccess
  }

  async handleToggleTest(record: IPushCampaign) {
    if (!this.validate(record, true)) {
      record.is_test_mode = false
      return
    }
    const isSuccess = await this.$store.dispatch(ACTION_PUSH.TEST_MODE, record)
    if (!isSuccess) {
      record.is_test_mode = false
    } else {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }
  }

  onDeliveredLog(pushId: string) {
    this.modalVisible = true
    this.pushId = pushId
  }

  onCloseModal() {
    this.modalVisible = false
  }

  onAdd(scenario_template_id) {
    if (scenario_template_id) {
      this.goto('push_setting', {
        app_id: this.selectedAppId,
        action_type: constant.ACTION_TYPE.ADD,
        scenario_template_id: scenario_template_id,
      })
    } else {
      this.goto('push_setting', { app_id: this.selectedAppId, action_type: constant.ACTION_TYPE.ADD })
    }
  }

  onEdit(data: IPushCampaign) {
    return this.detailPath('push_setting', {
      app_id: this.selectedAppId,
      push_id: data._id,
      action_type: constant.ACTION_TYPE.EDIT,
    })
  }

  onDetails(event, data: IPushCampaign) {
    // Check if Ctrl (Windows) or Meta (Mac) is pressed
    if (event.ctrlKey || event.metaKey) {
      // Let the browser handle opening in a new tab
      return
    }
    // Otherwise, navigate programmatically
    event.preventDefault()

    this.goto('push_details', {
      app_id: this.selectedAppId,
      campaign_type: 'push',
      campaign_id: data._id,
      from_date: DatetimeUtils.formatDate(this.dateRange.startDate),
      to_date: DatetimeUtils.formatDate(this.dateRange.endDate),
    })
  }

  getDetailsUrl(data) {
    // Construct the URL as a string for direct navigation
    return this.$router.resolve({
      name: 'push_details',
      params: {
        app_id: this.selectedAppId,
        campaign_type: 'push',
        story_id: data._id,
        from_date: DatetimeUtils.formatDate(this.dateRange.startDate),
        to_date: DatetimeUtils.formatDate(this.dateRange.endDate),
      },
    }).href
  }

  numberWithCommas(num) {
    return Formater.numberFormat(num)
  }

  roundNumber(num) {
    return Formater.round(num)
  }

  changeDateRange(value) {
    this.dateRange = value

    this.loadIntials()
  }

  validate(record: IPushCampaign, isTestMode: boolean) {
    let detail = record.prod_detail
    if (isTestMode) {
      detail = record.test_detail
    }
    if (detail?.audience?.audience_or?.length === 0) {
      this.$q.dialog({
        title: this.$t('error'),
        message: this.$t('validate.select_audience_or_and_not'),
        persistent: true,
      })
      return false
    }

    if (detail?.scenarios?.length === 0) {
      this.$q.dialog({
        title: this.$t('error'),
        message: this.$t('validate.please_design_scenario'),
        persistent: true,
      })
      return false
    }

    return true
  }
}
</script>
<style lang="sass" scoped></style>
